export const infoIcon = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1639_8679)">
<path d="M8.16016 14.25C11.6119 14.25 14.4102 11.4518 14.4102 8C14.4102 4.54822 11.6119 1.75 8.16016 1.75C4.70838 1.75 1.91016 4.54822 1.91016 8C1.91016 11.4518 4.70838 14.25 8.16016 14.25Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.16016 5.5V8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.16016 14.25C11.6119 14.25 14.4102 11.4518 14.4102 8C14.4102 4.54822 11.6119 1.75 8.16016 1.75C4.70838 1.75 1.91016 4.54822 1.91016 8C1.91016 11.4518 4.70838 14.25 8.16016 14.25Z" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.16016 5.5V8" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.16016 10.5H8.16641" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1639_8679">
<rect width="15" height="15" fill="white" transform="translate(0.660156 0.5)"/>
</clipPath>
</defs>
</svg>
`;
